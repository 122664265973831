import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"
import { useCheckout, useCheckoutContext } from "../../hooks/useCheckout"
import { useLanguage } from "../../hooks/useLanguage"
import { useRoutes } from "../../hooks/useRoutes"
import { useShopify } from "../../hooks/useShopify"
import { useSanity } from "../../hooks/useSanity"

export const withGiftCard = Component => ({ name = `GiftCard`, page }) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState(false)
  const [copied, setCopied] = useState(false)
  const {
    setActiveCart,
    config: {
      settings: { params, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { getUrlParameter } = useRoutes()
  const { formatMoney } = useShopify()
  const { textNormaliser } = useSanity()
  const locales = {
    ...useLanguage(`cart`),
    ...useLanguage(`giftcard`),
  }
  const { checkout } = useCheckoutContext()
  const { applyGiftCardCode, count } = useCheckout()

  const content = {
    ...page,
    title: textNormaliser(page?.title),
  }
  const giftCardJSON = isBrowser ? getUrlParameter(params.giftcard) : null
  let giftCard = null

  try {
    giftCard = giftCardJSON ? JSON.parse(atob(giftCardJSON)) : null
  } catch (error) {
    console.error(error)
  }

  const formatCode = code => code.split(``).map((letter, i) => (i % 4 === 0 ? ` ${letter}` : letter))

  const hasLineItems = count > 0

  const handleApply = async e => {
    e.preventDefault()

    setError(null)
    setLoading(true)
    const giftCards = await applyGiftCardCode(giftCard.code)

    setLoading(false)

    if (giftCards?.checkoutUserErrors?.[0]?.message) {
      setError(giftCards.checkoutUserErrors[0].message)
    }
  }

  const handleCart = () => setActiveCart(true)
  const handleCode = () => setCode(code => !code)
  const handleCopied = () => setCopied(true)
  const handleShop = () => navigate(routes.HOMEPAGE)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])

  const lastCharacters = giftCard?.code?.slice(-4)
  const outputCode = code ? giftCard?.code : lastCharacters?.padStart(giftCard.code.length, `*`)
  const date = giftCard?.expires_on ? new Date(giftCard.expires_on) : null
  const applied = checkout?.appliedGiftCards?.find(({ lastCharacters }) => giftCard?.code?.toLowerCase()?.includes(lastCharacters?.toLowerCase()))
  const giftCardApplicable = giftCard?.enabled && !giftCard?.expired

  Component.displayName = name
  return (
    <Component
      applied={applied}
      code={code}
      content={content}
      copied={copied}
      date={date}
      error={error}
      formatCode={formatCode}
      formatMoney={formatMoney}
      giftCard={giftCard}
      giftCardApplicable={giftCardApplicable}
      handleApply={handleApply}
      handleCart={handleCart}
      handleCode={handleCode}
      handleCopied={handleCopied}
      handleShop={handleShop}
      hasLineItems={hasLineItems}
      loading={loading}
      locales={locales}
      outputCode={outputCode}
    />
  )
}
